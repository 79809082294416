import {
  ButtonBlue,
  ButtonYellowFill,
  ButtonYellowOutline,
} from "components/common/CommonStyles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import JolPlayer from "jol-player";
import Footer from "../Footer";
import Header from "../Header";
import "./videoInterview.css";
import sample from "assets/sample.mp4";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { InterviewBoxStyled, InterviewPageBox } from "../InterviewStyled";
import { ToastStyles } from "components/common/ToastStyle";
import { toast, Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import ReactPlayer from "react-player";
export const VideoPage1 = () => {
  const [recordvideo, setRecordvideo] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const params = useParams();

  // webcam
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const recordedChunks = useRef([]);
  const videoConstraints = {
    height: 350,
    facingMode: "user",
    borderRadius: "20px",
  };
  const [video, setvideo] = useState("");
  const [testvideo, setTestvideo] = useState("");
  const name = Cookies.get("name");
  const role = Cookies.get("role");
  console.log(video);
  console.log("LocalStorage", localStorage);

  const userID = localStorage.getItem("userId");
  console.log("user ID:", userID);
  const handleStartCaptureClick = () => {
    setRunning(true);
    toast.success("Recording Started", ToastStyles);
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
          recordedChunks.current.push(event.data);
        });
        mediaRecorderRef.current.addEventListener("stop", () => {
          const videoBlob = new Blob(recordedChunks.current, {
            type: "video/webm",
          });
          setvideo(videoBlob);
          recordedChunks.current = [];
          setTestvideo(URL.createObjectURL(videoBlob));
        });
        mediaRecorderRef.current.start();
        setCapturing(true);
      });
  };
  const handleStopCaptureClick = () => {
    setRunning(false);
    mediaRecorderRef.current?.stop();
    setCapturing(false);
    setRecordvideo(true);
  };

  const recordagainstart = () => {
    setvideo([]);
    setTestvideo([]);
    handleClose();
  };
  const [time, setTime] = useState(0);

  const [running, setRunning] = useState(false);
  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!running) {
      clearInterval(interval);
      setTime(0);
    }
    return () => clearInterval(interval);
  }, [running]);

  const [isPermissions, setIsPermission] = useState(true);
  const [mediaShow, setMediaShow] = useState(false);

  // const checkPermission = async () => {
  //   navigator.mediaDevices
  //     .get({ audio: true, video: true })
  //     .then(function (stream) {
  //       console.log("accesed");
  //       setMediaShow(false);
  //       /* Use the stream */
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //       setMediaShow(true);
  //     });
  // };
  const checkPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      console.log(stream);
      console.log("Accessed");
      setMediaShow(false);
      // Use the stream if needed
    } catch (err) {
      console.log(err);
      setMediaShow(true);
    }
  };

  useEffect(() => {
    checkPermission();
  }, []);

  console.log("Media Show:", mediaShow);
  console.log("Test Video: ", testvideo);
  // const jobdetails = localStorage.getItem("jobDetails");
  const jobDetails = JSON.parse(localStorage.getItem("jobDetails"));
  const isCorporate = jobDetails?.creator_type === "corporate";
  return (
    <>
      <Modal show={mediaShow} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>
            <p className="text-center">Media Access not Found</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-5">
          <p className="text-center">
            Camera & MicroPhone Access not found Please Allow the permissions
            and Refresh the page to continue.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBlue
            onClick={() => {
              if (role === "interviewee") {
                navigate(-2);
              }
            }}
            className="w-100"
          >
            Back
          </ButtonBlue>
        </Modal.Footer>
      </Modal>
      <Toaster />
      <Header />
      <InterviewBoxStyled className="scroller interview-form h-70 padding-0">
        <InterviewPageBox className=" px-5">
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h5> Are you sure you want to record again?</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6>
                {" "}
                Do take note that this will require you to re-do the entire
                interview from question 1. Press the button Record Again to
                restart, or you can close this window and proceed to submit your
                responses.
              </h6>
            </Modal.Body>
            <Modal.Footer>
              <ButtonYellowOutline
                variant="secondary"
                onClick={recordagainstart}
              >
                Record Again
              </ButtonYellowOutline>
              <ButtonYellowFill width="120px" onClick={handleClose}>
                Close
              </ButtonYellowFill>
            </Modal.Footer>
          </Modal>
          <div className="col-12">
            {recordvideo ? (
              <>
                <div className="section-1 my-3 w-100 d-flex flex-column px-3">
                  <div className="d-flex justify-content-center mt-3">
                    <div className="col-md-6">
                      <div className="px-3 d-flex justify-content-center flex-column">
                        <h5 className="text-center">Hey! {name}</h5>
                        <h6 className="text-center">Check Your Video</h6>
                        <div className=" video-player pb-4">
                          {" "}
                          {/* <JolPlayer
                            className="player m-auto"
                            option={{
                              height: 300,
                              mode: "scaleToFill",
                              width: 400,
                              language: "en",
                              controls: true,
                              loop: true,
                              setBufferContent: "Loading...",
                              videoType: "video/mp4",
                              nextButton: true,
                              prevButton: true,
                              pausePlacement: "center",
                              isShowWebFullScreen: true,
                              isProgressFloat: true,
                              videoSrc: testvideo,
                            }}
                          /> */}
                          {/* <ReactPlayer
                            url={testvideo}
                            controls={true}
                            width="100%"
                            height="100%"
                          /> */}
                          <ReactPlayer
                            url={testvideo}
                            playing={false}
                            muted={true}
                            controls={true}
                            width="100%"
                            height="100%"
                            playsinline={true}
                          />
                        </div>
                        <div className="d-flex justify-content-center my-2">
                          {!isCorporate && (
                            <ButtonYellowOutline
                              onClick={() => {
                                setRecordvideo(false);
                              }}
                              style={{ width: "400px" }}
                            >
                              Record Again
                            </ButtonYellowOutline>
                          )}
                        </div>
                        <div className="d-flex justify-content-center my-3">
                          <ButtonYellowFill
                            onClick={() =>
                              navigate(
                                `/interview/video-interview2/${params.id}`
                              )
                            }
                            width="400px"
                          >
                            Okay, Let's Proceed
                          </ButtonYellowFill>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="section-1 d-flex flex-column px-3 ">
                  <h5 className="text-center">
                    Test your Audio video to start the interview process
                  </h5>
                  <h6 className="text-center">
                    Describe the Environment you are in.
                  </h6>
                  <div
                    style={{ width: "fit-content" }}
                    className="video-cam-box d-flex mx-auto"
                  >
                    {" "}
                    <div className="video-cam">
                      <div className="Container">
                        <div className="d-flex justify-content-center main-video">
                          <Webcam
                            style={{ objectFit: "cover" }}
                            height={350}
                            width="fit-content"
                            audio={true}
                            muted={true}
                            mirrored={true}
                            ref={webcamRef}
                            videoConstraints={videoConstraints}
                          />
                        </div>
                        <div className="d-flex justify-content-center my-2">
                          {/* {capturing ? (
                            <ButtonBlue onClick={handleStopCaptureClick} className="px-2">
                              Stop Capture
                            </ButtonBlue>
                          ) : (
                            <ButtonBlue onClick={handleStartCaptureClick} className="px-2">
                              Start Capture
                            </ButtonBlue>
                          )} */}
                          {/* {recordedChunks.length > 0 && (
                            <ButtonYellowOutline onClick={handleDownload}>
                              Download
                            </ButtonYellowOutline>
                          )} */}
                        </div>
                      </div>
                    </div>
                    {/* <WebCam className="video-cam" height="350" /> */}
                  </div>
                  <div
                    className="d-flex mx-auto justify-content-between prep-time mt-1"
                    style={{ width: "600px" }}
                  >
                    <h6>Preparation</h6>
                    <h6>(Time : {time}s)</h6>
                  </div>
                  <div className="d-flex justify-content-center my-1">
                    {capturing ? (
                      <ButtonYellowFill
                        onClick={() => {
                          handleStopCaptureClick();
                        }}
                        width="200px"
                        className="bg-red h-40"
                      >
                        Stop Recording
                      </ButtonYellowFill>
                    ) : (
                      <ButtonYellowFill
                        onClick={handleStartCaptureClick}
                        width="200px"
                        className="bg-green h-40"
                      >
                        Record Answer
                      </ButtonYellowFill>
                    )}
                  </div>
                  {/* <div className="d-flex mt-3">
                    <div className="col-8">
                      <div className="px-3">
                        <div className="video-player pb-4">
                          {" "}
                          <JolPlayer
                            className="player"
                            option={{
                              height: 400,
                              mode: "scaleToFill",
                              width: "fit-content",
                              language: "en",
                              controls: true,
                              loop: true,
                              setBufferContent: "Loading...",
                              videoType: "video/mp4",
                              nextButton: true,
                              prevButton: true,
                              pausePlacement: "center",
                              isShowWebFullScreen: true,
                              isProgressFloat: true,
                              videoSrc: sample,
                            }}
                          />
                        </div>

                        
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="px-3 ">
                        <div className="d-flex justify-content-center  flex-column">
                          <h6 className="purple text-center">
                            Complete Answer in 2 min
                          </h6>
                          <h6 className="yellow text-center">1:20/2:00</h6>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </>
            )}
          </div>
        </InterviewPageBox>
        <Footer />
      </InterviewBoxStyled>
    </>
  );
};
